import React from "react"
import WideImage from "../Partials/WideImage"
import { MaxWidthInnerContainer } from "../../styles/globalCss"
import { HeaderText, BodyText, JobPosition } from "../Partials/TextPieces"
import Spacer from "../Partials/Spacer"
import { get } from "lodash"

const SinglePerson = ({ person = {} }) => {
    const { fluid: bkgImg } = get(person, "image", {})
    const { description } = get(person, "image")

    return (
        <React.Fragment>
            <WideImage img={bkgImg} description={description} />
            <MaxWidthInnerContainer>
                <HeaderText copy={person.nameOfPerson} mTop />
                <JobPosition>{person.jobTitle.toUpperCase()}</JobPosition>
                <Spacer size="xsmall" />
                <BodyText
                    copy={person.biography.childMarkdownRemark.html}
                    padding
                />
                <Spacer size="large" />
            </MaxWidthInnerContainer>
        </React.Fragment>
    )
}

export default SinglePerson
