import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const WideImage = ({ img, description }) => (
    <div>
        <ImageContainer>
            {img && <Img fluid={img} alt={description} />}
        </ImageContainer>
        <GhostPadding />
    </div>
)
export default WideImage

const ImageContainer = styled.div`
    width: 100%;
    position: absolute;
    z-index: -1;
    background-color: #d8d8d8;
    /* height: 528px; */
`
const GhostPadding = styled.div`
    position: relative;
    padding-top: 24.55%;
    @media only screen and (max-width: 375px) {
        padding-top: 37%;
    }
`
