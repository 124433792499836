import React from "react"
import SinglePerson from "./SinglePerson"

const PeopleList = ({ people = [] }) => {
    return people.map(({ id, ...person }) => (
        <SinglePerson key={id} person={person} />
    ))
}

export default PeopleList
