import React from "react"
import ContactUsForm from "../components/Forms/ContactUsForm"
import SEO from "../components/utils/seo"
import { graphql } from "gatsby"
import { get } from "lodash"
import BackgroundImage from "../components/Partials/BackgroundImage"
import PeopleList from "../components/About/PeopleList"

const About = ({ data }) => {
    const people = get(data, "contentfulAboutPage.people", [])
    const { fluid: bkgImg } = get(
        data,
        "contentfulAboutPage.backgroundImage",
        {}
    )
    const { description } = get(data, "contentfulAboutPage.backgroundImage")

    return (
        <>
            <SEO title="About" />
            <BackgroundImage bkgImg={bkgImg} description={description} />
            <PeopleList people={people} />
            <ContactUsForm bkgImg={bkgImg} description={description} />
        </>
    )
}

export default About

export const query = graphql`
    query {
        contentfulAboutPage {
            backgroundImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            people {
                image {
                    description
                    fluid(maxWidth: 1440, quality: 75) {
                        ...GatsbyContentfulFluid
                    }
                }
                jobTitle
                nameOfPerson
                biography {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
